interface ProjectNameMapping {
  [key: string]: string
}

export const projectNameMapping: ProjectNameMapping = {
  alarmanlagen: 'alarmSystem',
  'architekt-bauleitung': 'architect',
  'markisen-sonnenstoren': 'awnings',
  'metallbau-balkon': 'balcony',
  bad: 'bath',
  carport: 'carport',
  makler: 'broker',
  schreiner: 'carpenter',
  elektriker: 'electrician',
  'zaun-und-sichtschutz': 'fences',
  bodenleger: 'floorer',
  garage: 'garage',
  gaertner: 'gardener',
  heizung: 'heating',
  ladestation: 'chargingColumn',
  klimaanlage: 'airConditioning',
  kuechen: 'kitchen',
  metallbau: 'metalConstruction',
  maler: 'painter',
  'metallbau-ueberdachung-pergola': 'pergola',
  'metallbau-gelaender': 'railing',
  'storen-rolladen': 'rollerBlinds',
  dachdecker: 'roofer',
  solaranlagen: 'solarPlants',
  'solar-plants': 'solarPlants',
  'metallbau-treppe': 'stair',
  wasseraufbereitung: 'waterConditioning',
  whirlpool: 'whirlpool',
  'fenster-und-tueren': 'window',
  fensterkaufen: 'window',
  zimmermann: 'chippie',
  klima: 'airConditioning',
  montage: 'assembling',
  plattenleger: 'paver',
  'isolation-fassade': 'facade',
  wintergarten: 'wintergarden',
  airconditioning: 'airconditioning',
  alarmsystem: 'alarmSystem',
  architect: 'architect',
  assembling: 'assembling',
  awnings: 'awnings',
  balcony: 'balcony',
  bath: 'bath',
  broker: 'broker',
  carpenter: 'carpenter',
  chargingcolumn: 'chargingColumn',
  chippie: 'chippie',
  electrician: 'electrician',
  fence: 'fence',
  floorer: 'floorer',
  gardener: 'gardener',
  heating: 'heating',
  isolation: 'facade',
  kitchen: 'kitchen',
  metalconstruction: 'metalConstruction',
  painter: 'painter',
  paver: 'paver',
  pergola: 'pergola',
  railing: 'railing',
  rollerblinds: 'rollerBlinds',
  roofer: 'roofer',
  solarplants: 'solarPlants',
  stair: 'stair',
  waterconditioning: 'waterConditioning',
  window: 'window',
  wintergarden: 'wintergarden',
  solaranlagekaufen: 'solarPlants',
  maklervergleichen24: 'broker',
  meinMakler: 'broker',
  maklerLotse: 'broker',
  'offres-cuisines': 'kitchen',
}
