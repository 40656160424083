import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const userTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'user',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  userData: {},
  userId: undefined,
})

export const getters = {
  getUserData(state: any) {
    try {
      return state.userData
    } catch {
      return {}
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch {
      return undefined
    }
  },
}

export const mutations = {
  setUserData(state: any, userData: object) {
    state.userData = userData
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
          state = localState
  },
  async setUserDataLocal(state: any) {
    await userTable.setItem('userData', state)
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets user from localForage first
    const userData: any | null = await userTable.getItem('userData')
    if (userData) {
      context.commit('setState', userData)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setUserData', data.user.body)
      context.commit('setUserId', data.user.body.id)
      context.commit('setUserDataLocal')
    })
  },
  /* eslint-disable */
        async postMutation(context: any, data: any) {
          /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
}
