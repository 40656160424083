import Vue from 'vue'

const setVisibility = (el: HTMLElement, binding: any) => {
  el.style.visibility = binding.value ? '' : 'hidden'
}

Vue.directive('hide', {
  bind: setVisibility,
  update: setVisibility,
})
