
import Vue from 'vue'
export default Vue.extend({
  name: 'MyHausHeldLogo',
  props: {
    width: {
      type: String,
      default: '138',
      required: false,
    },
    height: {
      type: String,
      default: '24.207',
      required: false,
    },
  },
})
