/**
 * Gets a cookie
 * @param name {string} - Name of the cookie
 * @returns {string|undefined} - Returns the cookie with provided name
 */
export const getCookie = (name: string): string | undefined => {
  return window.document.cookie.split(';').find((cookie) => cookie.includes(name))
}

/**
 * Sets a cookie
 * @param cookie {string} - String representatino of the cookie which shall be set
 */
export const setCookie = (cookie: string): void => {
  window.document.cookie = cookie
}

/**
 * Reads current cookieConsent and activates all cookies and scripts
 * for which the consent was given
 */
export const activateCookiesAndScripts = (optIn: boolean): void => {
  const cookieConsent = getCookie('cookieConsent=')
  // If there is a cookieConsent
  if (cookieConsent !== undefined) {
    // Gets the selected options
    const selectedOptions = cookieConsent.split('=')[1].split(',')
    // Applies analytics and marketing cookies
    if (selectedOptions.includes('analytics')) {
      const analyticsScripts: NodeListOf<Element> = window.document.querySelectorAll(
        'script[data-consentCategory="analytics"]'
      )
      analyticsScripts.forEach((script: Element) => {
        activateScript(script)
      })
    }
  } else if (cookieConsent === undefined && !optIn) {
    // Sets cookieConsent cookie for further usage
    setCookie('cookieConsent=functional,analytics; max-age=31536000; Secure; path=/')
    // Applies analytics and marketing cookies
    const analyticsScripts: NodeListOf<Element> = window.document.querySelectorAll(
      'script[data-consentCategory="analytics"]'
    )
    analyticsScripts.forEach((script: Element) => {
      activateScript(script)
    })
  }
}
/**
 * Activates HTML Script
 * @param script {Element} - HTML script element
 */
const activateScript = (script: Element): void => {
  script.remove()
  script.removeAttribute('type')
  window.document.head.appendChild(script)
}

/**
 * Function which handles cookie consent
 * @param component - Vue component which calls function
 */
export const handleCookieConsent = (component: any) => {
  if (process.browser) {
    if (component.$config.cookieOptIn) {
      // Checks if the user has already given his consent to our cookies. Shows cookie consent modal if not.
      const showCookieConsent: string | undefined = getCookie('showCookieConsent=')
      if (showCookieConsent === undefined) {
        component.$data.showCookieConsent = true
      } else {
        component.$data.showCookieConsent = false
      }
    }
    // Reads cookie configuration and applies the cookies and scripts
    activateCookiesAndScripts(component.$config.cookieOptIn)
    // Sets newsletter cookie when newsletter url query is provided
    // and the consent for analytics was given and no newsletter cookie is present
    if (component.$route.query.newsletter === 'true') {
      const cookieConsent = getCookie('cookieConsent=')
      if (
        !component.$config.cookieOptIn ||
        (cookieConsent !== undefined && cookieConsent.includes('analytics'))
      ) {
        // Pushes a custom event to google analytics
        // @ts-ignore
        dataLayer?.push({
          event: 'newsletter-conversion',
          conversionValue: 'nlView',
          urlValue: component.$route.path,
        })
        setCookie('newsletter=true; max-age=86400; Secure; path=/')
      }
    }
  }
}

/**
 * Functions saves referrer url to a cookie with max age 1 day
 * @param component - Vue component which calls function
 */
export const setReferrerCookie = (component: any): void => {
  if (process.browser) {
    if (
      document.referrer !== '' &&
      !document.referrer.includes(component.$config.baseUrl.replace('https://', ''))
    ) {
      setCookie(`referrer=${document.referrer}; max-age=86400; Secure; path=/`)
    } else if (component.$route.query.utm_source === 'baumarkt') {
      setCookie(`referrer=https://jumbo.ch (iFrame); max-age=86400; Secure; path=/`)
    }
  }
}
