
import Vue from 'vue'
export default Vue.extend({
  props: {
    centered: {
      type: Boolean,
      default: false,
      required: false,
    },
    button: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
})
