var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-100"},[_c('a',{staticClass:"skip-link",attrs:{"href":"#main-content"}},[_vm._v(_vm._s(_vm.$t('global.skipToContent')))]),_vm._v(" "),_c('TopNavigation',{attrs:{"at-the-top":_vm.atTheTop}}),_vm._v(" "),_c('main',{staticClass:"mt-4 mt-l-6",attrs:{"id":"main-content"}},[_c('Nuxt',{attrs:{"keep-alive":""}}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-center"},[_c('CallToAction',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.atTheTop &&
          !_vm.$route.name.includes('projects') &&
          !_vm.$route.name.includes('partnership') &&
          !_vm.$route.name.includes('wir') &&
          !_vm.$route.name.includes('mortgage') &&
          !_vm.$route.name.includes('sponsoring') &&
          _vm.$data.articleObject.ctaButtonText !== null
        ),expression:"\n          !atTheTop &&\n          !$route.name.includes('projects') &&\n          !$route.name.includes('partnership') &&\n          !$route.name.includes('wir') &&\n          !$route.name.includes('mortgage') &&\n          !$route.name.includes('sponsoring') &&\n          $data.articleObject.ctaButtonText !== null\n        "}],staticClass:"d-block-l",attrs:{"text":_vm.getCTAText(_vm.$route.name)},nativeOn:{"click":function($event){return _vm.triggerAction(_vm.$route.name)}}})],1)],1),_vm._v(" "),(_vm.showCookieConsent)?_c('CookieConsent'):_vm._e(),_vm._v(" "),_c('FooterComponent',{attrs:{"phone":_vm.$route.name.includes('get-partner') ? '071 508 72 05' : '071 508 72 04',"email":_vm.$route.name.includes('get-partner') ? 'partner@hausheld.ch' : 'kontakt@hausheld.ch'}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }