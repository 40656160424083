
import Vue from 'vue'
import CallToAction from '~/components/buttons/CallToAction.vue'

export default Vue.extend({
  components: { CallToAction },
  data() {
    return {
      showMortgageOptions: false,
    }
  },
  methods: {
    revealOptions(event: any) {
      if (event.target.id === 'mortgage') {
        event.stopPropagation()
      }
      this.$data.showMortgageOptions = !this.$data.showMortgageOptions
    },
  },
})
