
import Vue from 'vue'
export default Vue.extend({
  props: {
    hasButtonLight: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    getCurrentRealEstateAddress() {
      return this.$store.getters['analysis/getCurrentRealEstateAddress']
    },
    getRealEstateAddresses(): string[] {
      return this.$store.getters['analysis/getRealEstateAddresses']
    },
    changeRealEstate(address: string) {
      const addresses = this.getRealEstateAddresses()
      const pos = addresses.indexOf(address)
      this.$store.commit('analysis/setRealEstate', pos)
      this.$nuxt.$emit('updateRealEstate')
      this.$nuxt.refresh()
    },
  },
})
