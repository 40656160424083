import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const documentationsTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'documentations',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  documentations: [],
  userId: undefined,
})

export const getters = {
  getDocumentations(state: any) {
    try {
      return state.documentations
    } catch {
      return []
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch {
      return undefined
    }
  },
}

export const mutations = {
  setDocumentations(state: any, documentations: object[]) {
    state.documentations = documentations
  },
  addProjectTagsToDocumentations(state: any, projects: any[]) {
    for (const documentation of state.documentations) {
      if (documentation.body.ownerType === 'PROJECT') {
        for (const project of projects) {
          if (project.body.id === documentation.body.ownerId) {
            documentation.body.tag = project.body.request.body.craftType
          }
        }
      }
    }
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
        state = localState
  },
  async setDocumentationsLocal(state: any) {
    await documentationsTable.setItem('documentations', state)
  },

  /**
   * Sorts documentations by state and then by creation date
   */
  sortToDos(state: any) {
    const sortOrder = ['OPEN', 'DONE']
    const documentations = state.documentations
    documentations.sort(function (a: any, b: any) {
      if (a.body.status === b.body.status) {
        return a.body.creationDate < b.body.creationDate ? 1 : -1
      }
      return sortOrder.indexOf(a.body.status) - sortOrder.indexOf(b.body.status)
    })
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets user from localForage first
    const documentations: any | null = await documentationsTable.getItem('documentations')
    if (documentations) {
      context.commit('setState', documentations)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setDocumentations', data.user.body.documentations)
      context.commit('addProjectTagsToDocumentations', data.user.body.projects)
      context.commit('setUserId', data.user.body.id)
      context.commit('setDocumentationsLocal')
    })
  },
  /* eslint-disable */
      async postMutation(context: any, data: any) {
        /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
}
