
import Vue from 'vue'
import Logo from '~/components/images/HausHeldLogo.vue'
import MenuListLarge from '~/components/navigations/hausHeld/MenuListLarge.vue'
import GetQuoteNow from '~/components/navigations/hausHeld/GetQuoteNow.vue'
import MenuListSmall from '~/components/navigations/hausHeld/MenuListSmall.vue'

export default Vue.extend({
  name: 'TopNavigation',
  components: { Logo, MenuListLarge, GetQuoteNow, MenuListSmall },
  props: {
    atTheTop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      burgerMenuOpened: false,
    }
  },
  methods: {
    triggerMobileMenu() {
      this.$data.burgerMenuOpened = !this.$data.burgerMenuOpened
    },
  },
})
