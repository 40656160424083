
import Vue from 'vue'
import CookieConsent from '~/components/cookieConsent/CookieConsent.vue'
import TopNavigation from '~/components/navigations/myHausHeld/desktop/TopNavigation.vue'
import { handleCookieConsent, setReferrerCookie } from '~/core/functions/cookie'

export default Vue.extend({
  name: 'LandingLayout',
  components: { TopNavigation, CookieConsent },
  data() {
    return {
      showCookieConsent: false,
    }
  },
  created() {
    setReferrerCookie(this)
    handleCookieConsent(this)
  },
})
