
import Vue from 'vue'
export default Vue.extend({
  computed: {
    title() {
      return this.$store.state.metaData.title
    },
  },
  methods: {
    arrowBack() {
      return this.$store.state.metaData.arrowBack
    },
    plusSign() {
      return this.$store.state.metaData.plusSign
    },
    questionmark() {
      return this.$store.state.metaData.questionmark
    },
    emitingAddMobileHeaderEvent() {
      this.$nuxt.$emit('addEventMobileHeader')
    },
    emitingOnboardingMobileHeaderEvent() {
      this.$nuxt.$emit('onboardingEventMobileHeader')
    },
  },
})
