
import Vue from 'vue'
import Logo from '~/components/images/AufzugoLogo.vue'
import MenuListLarge from '~/components/navigations/aufzugo/MenuListLarge.vue'
import MenuListSmall from '~/components/navigations/aufzugo/MenuListSmall.vue'

export default Vue.extend({
  name: 'TopNavigation',
  components: { Logo, MenuListLarge, MenuListSmall },
  data() {
    return {
      burgerMenuOpened: false,
    }
  },
  methods: {
    triggerMobileMenu() {
      this.$data.burgerMenuOpened = !this.$data.burgerMenuOpened
    },
  },
})
