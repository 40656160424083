import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const realEstateTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'realEstate',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  realEstate: {},
})

export const getters = {
  getRealEstateId(state: any) {
    try {
      return state.realEstate.body.id
    } catch {
      return undefined
    }
  },
  getRealEstate(state: any): object {
    try {
      return state.realEstate
    } catch {
      return {}
    }
  },
  getComponents(state: any) {
    try {
      return state.realEstate.body.components
    } catch {
      return []
    }
  },
}

export const mutations = {
  setRealEstate(state: any, realEstate: object) {
    state.realEstate = realEstate
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
    state = localState
  },
  async setUserLocal(state: any) {
    await realEstateTable.setItem(`realEstate${state.realEstate.body.id}`, state)
  },
  sortComponentByGoodConditionUntil(state: any) {
    try {
      return state.realEstate.body.components.sort((a: any, b: any) => {
        return a.body.goodConditionUntil < b.body.goodConditionUntil ? -1 : 1
      })
    } catch (error) {
      return []
    }
  },
}

export const actions = {
  async getDataOffline(context: any, data: any) {
    // Gets user from localForage first
    const realEstate: any | null = await realEstateTable.getItem(`realEstate${data.variables.id}`)
    if (realEstate) {
      context.commit('setState', realEstate)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setRealEstate', data.realEstate)
      context.commit('setUserLocal')
    })
  },
  /* eslint-disable */
  async postMutation(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
  async getComponentById(context: any, id: number) {
    const components = await context.getters.getComponents
    try {
      for (const component of components) {
        if (component.body.id === id) {
          return component
        }
      }
    } catch (error) {
      return {}
    }
  },
}
