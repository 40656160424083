import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const projectTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'project',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  project: {},
  craftsmen: [],
})

export const getters = {
  getProject(state: any) {
    try {
      return state.project
    } catch {
      return {}
    }
  },
}

export const mutations = {
  setProject(state: any, project: object) {
    state.project = project
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
    state = localState
  },
  async setProjectLocal(state: any) {
    await projectTable.setItem(`project${state.project.body.id}`, state)
  },

  /**
   * Sorts quotes of a particular project by quotes last change
   */
  sortByQuotesOfProjectLastChange(state: any) {
    // Sorts quotes of the project by the last quote change
    state.project.body.quotes = state.project.body.quotes.sort((a: any, b: any) => {
      return a.body.lastChange < b.body.lastChange ? -1 : 1
    })
    return state.project
  },

  /**
   * Sorts quotes of a particular project by name
   */
  sortByQuotesOfProjectName(state: any) {
    // Sorts the quotes of the project by name
    state.project.body.quotes = state.project.body.quotes.sort((a: any, b: any) => {
      return a.body.craftsman.body.name < b.body.craftsman.body.name ? 1 : -1
    })
    return state.project
  },

  /**
   * Sorts quotes of a particular project by price
   */
  sortByQuotesOfProjectPrice(state: any) {
    // Sorts the quotes of the project by price
    state.project.body.quotes = state.project.body.quotes.sort((a: any, b: any) => {
      return a.body.price > b.body.price ? 1 : -1
    })
    return state.project
  },

  /**
   * Sorts todos by state and then by creation date
   */
  sortToDos(state: any) {
    const sortOrder = ['OPEN', 'DONE']
    try {
      // Some to dos have a different project depth
      if (state.project.project) {
        const todos = state.project.project.body.todos
        todos.sort(function (a: any, b: any) {
          if (a.body.status === b.body.status) {
            return a.body.creationDate < b.body.creationDate ? 1 : -1
          }
          return sortOrder.indexOf(a.body.status) - sortOrder.indexOf(b.body.status)
        })
      } else {
        const todos = state.project.body.todos
        todos.sort(function (a: any, b: any) {
          if (a.body.status === b.body.status) {
            return a.body.creationDate < b.body.creationDate ? 1 : -1
          }
          return sortOrder.indexOf(a.body.status) - sortOrder.indexOf(b.body.status)
        })
      }
    } catch {}
  },
}

export const actions = {
  async getDataOffline(context: any, data: any) {
    // Gets projects from localForage first
    const project: any | null = await projectTable.getItem(`project${data.variables.id}`)
    if (project) {
      context.commit('setProject', project)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setProject', data.project)
      context.commit('setProjectLocal')
    })
  },
  /* eslint-disable */
  async getSignedUrl(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.query, data.variables).then((data) => {
      return data
    })
  },
  /* eslint-disable */
  async postMutation(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
}
