
import Vue from 'vue'
import ChooseProjectDropdown from '~/components/dropdowns/ChooseProjectDropdown.vue'

export default Vue.extend({
  name: 'MenuListLarge',
  components: {
    ChooseProjectDropdown,
  },
  props: {
    atTheTop: {
      type: Boolean,
      required: true,
    },
  },
})
