
import Vue from 'vue'
import Toggle from '~/components/toggle/Toggle.vue'
import { activateCookiesAndScripts, getCookie, setCookie } from '~/core/functions/cookie'
export default Vue.extend({
  components: { Toggle },
  data() {
    return {
      functional: true,
      analytics: false,
      overlayActive: true,
    }
  },
  beforeMount() {
    if (!this.$config.cookieOptIn) {
      if (getCookie('cookieConsent=') === undefined) {
        this.$data.analytics = true
      } else {
        this.$data.analytics = getCookie('analytics') !== undefined
      }
    } else {
      this.$data.analytics = getCookie('analytics') !== undefined
    }
    this.triggerOverlay()
  },
  beforeUpdate() {
    this.triggerOverlay()
  },
  methods: {
    triggerOverlay(): void {
      this.$route.name?.includes('dataProtection')
        ? (this.$data.overlayActive = false)
        : (this.$data.overlayActive = true)
    },
    triggerToggle(name: string): void {
      this.$data[name] = !this.$data[name]
    },
    triggerConsentAction(consentType: string): void {
      if (consentType === 'all') {
        this.setCookie('cookieConsent=functional,analytics; max-age=31536000; Secure; path=/')
        this.setCookie('showCookieConsent=true; max-age=31536000; Secure; path=/')
        this.triggerToggle('analytics')
      } else if (consentType === 'custom') {
        const options = ['functional', 'analytics']
        let selectedOptions: string = ''
        for (const option of options) {
          if (this.$data[option]) {
            selectedOptions = selectedOptions.concat(option + ',')
          }
        }
        this.setCookie(`cookieConsent=${selectedOptions}; max-age=31536000; Secure; path=/`)
        this.setCookie('showCookieConsent=true; max-age=31536000; Secure; path=/')
      }
      activateCookiesAndScripts(true) // @ts-ignore
      this.$parent.$data.showCookieConsent = false
    },
    setCookie,
  },
})
