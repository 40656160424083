import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const projectsTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'projects',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  projects: [],
  craftsmen: [],
  userId: undefined,
})

export const getters = {
  getProjects(state: any) {
    try {
      return state.projects
    } catch {
      return []
    }
  },
  getCraftsmen(state: any): object[] {
    try {
      return state.craftsmen
    } catch {
      return []
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch {
      return undefined
    }
  },
}

export const mutations = {
  setProjects(state: any, projects: object[]) {
    state.projects = projects
  },
  setCraftsmen(state: any, craftsmen: object[]) {
    state.craftsmen = craftsmen
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
    state = localState
  },
  async setProjectsLocal(state: any) {
    await projectsTable.setItem('projects', state)
  },

  /**
   * Sorting all projects by creationDate
   * */
  sortByCreationDate(state: any) {
    try {
      return state.projects.sort((a: any, b: any) =>
        a.body.creationDate < b.body.creationDate ? 1 : -1
      )
    } catch {
      return []
    }
  },

  /**
   * Sort all projects by status
   */
  sortByStatus(state: any) {
    try {
      return state.projects.sort((a: any, b: any): number => {
        // Tries to sort projects by their request state. Catches error if request
        // is undefined (i.e. custom projects)
        try {
          return Number(a.body.request.body.details.state.slice(0, 1)) <
            Number(b.body.request.body.details.state.slice(0, 1))
            ? -1
            : 1
        } catch {
          return 1
        }
      })
    } catch {
      return []
    }
  },

  /**
   * Sorts quotes of a particular project by quotes last change
   */
  sortByQuotesOfProjectLastChange(state: any, id: Number) {
    for (const project in state.projects) {
      if (state.projects[project].body.id !== id) {
        continue
      }
      // Sorts quotes of the project by the last quote change
      state.projects[project].body.quotes = state.projects[project].body.quotes.sort(
        (a: any, b: any) => {
          return a.body.lastChange < b.body.lastChange ? -1 : 1
        }
      )
    }
    return state.projects
  },

  /**
   * Sorts quotes of a particular project by name
   */
  sortByQuotesOfProjectName(state: any, id: Number) {
    for (const project in state.projects) {
      if (state.projects[project].body.id !== id) {
        continue
      }
      // Sorts the quotes of the project by name
      state.projects[project].body.quotes = state.projects[project].body.quotes.sort(
        (a: any, b: any) => {
          return a.body.craftsman.body.name < b.body.craftsman.body.name ? 1 : -1
        }
      )
    }
    return state.projects
  },

  /**
   * Sorts quotes of a particular project by price
   */
  sortByQuotesOfProjectPrice(state: any, id: Number) {
    for (const project in state.projects) {
      if (state.projects[project].body.id !== id) {
        continue
      }
      // Sorts the quotes of the project by price
      state.projects[project].body.quotes = state.projects[project].body.quotes.sort(
        (a: any, b: any) => {
          return a.body.price > b.body.price ? 1 : -1
        }
      )
    }
    return state.projects
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets projects from localForage first
    const projects: any | null = await projectsTable.getItem('projects')
    if (projects) {
      context.commit('setState', projects)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setProjects', data.user.body.projects)
      context.commit('setCraftsmen', data.user.body.craftsmen)
      context.commit('setUserId', data.user.body.id)
      context.commit('setProjectsLocal')
    })
  },
  /* eslint-disable */
  async getSignedUrl(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.query, data.variables).then((data) => {
      return data
    })
  },
  /* eslint-disable */
  async postMutation(context: any, data: any) {
    /* eslint-enable */
    const c = data.apolloPreflight
      ? client(cleanToken(data.token), data.apolloPreflight)
      : client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
  async getProjectById(context: any, data: any) {
    const projects = await context.getters.getProjects
    try {
      for (const project of projects) {
        if (project.body.id === data.id) {
          return project
        }
      }
    } catch (e) {
      return {}
    }
  },
}
