
import Vue from 'vue'
import CookieConsent from '~/components/cookieConsent/CookieConsent.vue'
import Logo from '~/components/images/MyHausHeldLogo.vue'
import { head } from '~/core/config/head.config'
import { handleCookieConsent, setReferrerCookie } from '~/core/functions/cookie'

export default Vue.extend({
  name: 'AuthLayout',
  components: { Logo, CookieConsent },
  data() {
    return {
      showCookieConsent: false,
    }
  },
  head() {
    head.bodyAttrs.class = 'user-area-theme'
    return head
  },
  created() {
    setReferrerCookie(this)
    handleCookieConsent(this)
  },
})
