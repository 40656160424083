import { GraphQLClient } from 'graphql-request'

// @ts-ignore
const endpoint: string = process.env.dataService

const client = (token: string, apolloPreflight: string = 'false'): GraphQLClient => {
  return new GraphQLClient(endpoint, {
    mode: 'cors',
    headers: {
      'Apollo-Require-Preflight': apolloPreflight,
      Authorization: token,
    },
  })
}

export default client
