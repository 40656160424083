
import Vue from 'vue'
import { capitalizeFirstLetterOnly } from '~/core/functions/capitalizeFirstLetterOnly'

export default Vue.extend({
  name: 'FooterComponent',
  props: {
    phone: {
      type: String,
      default: '071 508 72 04',
      required: false,
    },
    email: {
      type: String,
      default: 'kontakt@hausheld.ch',
      required: false,
    },
  },
  methods: {
    capitalizeFirstLetterOnly,
  },
})
