export const state = () => ({
  title: '',
  arrowBack: false,
  plusSign: false,
  questionmark: false,
})

export const mutations = {
  setTitle(state: any, title: string): void {
    state.title = title
  },
  setArrowBack(state: any, displayArrowBack: boolean): void {
    state.arrowBack = displayArrowBack
  },
  setPlusSign(state: any, displayPlusSign: boolean): void {
    state.plusSign = displayPlusSign
  },
  setQuestionmark(state: any, displayQuestionmark: boolean): void {
    state.questionmark = displayQuestionmark
  },
}
