
import Vue from 'vue'
import CallToAction from '~/components/buttons/CallToAction.vue'
import CookieConsent from '~/components/cookieConsent/CookieConsent.vue'
import FooterComponent from '~/components/navigations/hausHeld/Footer.vue'
import TopNavigation from '~/components/navigations/hausHeld/TopNavigation.vue'
import { handleCookieConsent, setReferrerCookie } from '~/core/functions/cookie'

export default Vue.extend({
  name: 'MainLayout',
  components: { TopNavigation, CallToAction, FooterComponent, CookieConsent },
  data() {
    return {
      atTheTop: true,
      showCookieConsent: false,
      articleObject: {},
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.changeNavbarOnScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.changeNavbarOnScroll)
  },
  created() {
    setReferrerCookie(this)
    handleCookieConsent(this)
    this.$nuxt.$on('emitArticle', (article: Object) => {
      this.$data.articleObject = article
    })
  },
  methods: {
    changeNavbarOnScroll() {
      const stickyBreakPoint = window.innerHeight
      this.$data.atTheTop = !(window.pageYOffset >= stickyBreakPoint)
    },
    getCTAText(route: string): string {
      if (route.includes('get-partner')) {
        return this.$t('partner.bannerButtonText').toString()
      } else if (route.includes('freeTestForPartner')) {
        return this.$t('partner.bannerButtonTextFree').toString()
      } else if (route.includes('partnership')) {
        return this.$t('partner.bannerButtonTextFree').toString()
      } else if (route.includes('counselor') && this.$data.articleObject.ctaButtonText) {
        return this.$data.articleObject.ctaButtonText
      }
      return this.$t('global.startProjectNow').toString()
    },
    triggerAction(route: string): void {
      if (route.includes('get-partner')) {
        this.$router.push(`${this.localePath('/get-partner/')}#${this.$t('anchors.partnerForm')}`)
      } else if (route.includes('freeTestForPartner')) {
        this.$router.push(
          `${this.localePath('/freeTestForPartner/')}#${this.$t('anchors.freeTest')}`
        )
      } else if (route.includes('partnership')) {
        this.$router.push(
          `${this.localePath('/freeTestForPartner/')}#${this.$t('anchors.freeTest')}`
        )
      } else if (
        route.includes('counselor') &&
        this.$data.articleObject.ctaButtonText !== undefined
      ) {
        this.$router.push(this.$data.articleObject.ctaButtonLink)
      } else {
        this.$router.push(this.localePath('/projects/'))
      }
    },
  },
})
