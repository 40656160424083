interface BrowserInformation {
  timeOpened: Date
  timeZone: Number
  page: String
  referrer: String
  previousSites: String
  previousSitesLength: Number
  browserVersion: String
  browserLanguage: String
  online: Boolean
  cookiesEnabled: Boolean
  cookies: String
  localStorage: Storage
  screenWidth: Number
  screenHeight: Number
  innerWidth: Number
  innerHeight: Number
  availableWidth: Number
  availableHeight: Number
  colorDepth: Number
  pixelDepth: Number
  additionalInformation?: String
}

export const gatherBrowserInformation = (additionalInformation?: String): BrowserInformation => {
  return {
    timeOpened: new Date(),
    timeZone: new Date().getTimezoneOffset() / 60,
    page: window.location.href,
    referrer: document.referrer,
    previousSites: JSON.stringify(history),
    previousSitesLength: history.length,
    browserVersion: navigator.userAgent,
    browserLanguage: navigator.language,
    online: navigator.onLine,
    cookiesEnabled: navigator.cookieEnabled,
    cookies: document.cookie,
    localStorage,
    screenWidth: screen.width,
    screenHeight: screen.height,
    innerWidth,
    innerHeight,
    availableWidth: screen.availWidth,
    availableHeight: screen.availHeight,
    colorDepth: screen.colorDepth,
    pixelDepth: screen.pixelDepth,
    additionalInformation,
  }
}
