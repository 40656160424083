import { capitalizeFirstLetterOnly } from '~/core/functions/capitalizeFirstLetterOnly'

interface metaData {
  title: string
  description: string
  keywords: string
}

interface metaDataObject {
  [key: string]: metaData
}

export const getSiteName = (): string => {
  if (process.env.SATELLITE_NAME !== undefined) {
    return capitalizeFirstLetterOnly(process.env.SATELLITE_NAME) + '.ch'
  } else {
    return 'HausHeld.ch'
  }
}

export const meta: metaDataObject = {
  landingDE: {
    title: 'Kostenlose Handwerker-Offerten für Ihre Renovation | HausHeld.ch',
    description:
      'Offerten-Anfragen? Mit HausHeld kostenlos bis zu drei Offerten für Ihre Sanierung & Renovation erhalten - von Schweizer Fachbetrieben aus Ihrer Region!',
    keywords: 'Handwerker, Offerte, Offerten-Anfrage, Renovation',
  },
  landingFR: {
    title: `Offres gratuites d'artisans pour votre rénovation | ${getSiteName()}`,
    description: `Demande d'offres ? Avec HausHeld, recevez gratuitement jusqu'à trois offres pour votre assainissement & rénovation - de la part d'entreprises suisses spécialisées de votre région!`,
    keywords: `Artisan, offre, demande d'offre, rénovation`,
  },
  mhLanding: {
    title: 'myHausHeld – Das digitale Verwaltungstool für Ihr Zuhause',
    description:
      'Digitales Gebäudemanagement neu erfunden! Erfassen Sie Ihre Immobilien, Identifizieren Sie Renovations- und Optimierungsbedarfe und Managen Sie Ihre Renovationsprojekte mit einer einzigen Software.',
    keywords: 'Digitales Gebäudemanagement, Haus-Verwaltungstool, Renovationsmanagement',
  },
  projects: {
    title: 'Günstige Handwerker gesucht und gefunden! Offerten einholen um Ihr Projekt zu Starten',
    description:
      'Günstige Handwerker-Helden | Schildern Sie Ihr Projekt für Vergleichsofferten bei Renovierungen oder einem Neubau von geprüften Anbietern',
    keywords: 'Günstige Handwerker gesucht, Offerten Einholen, Vergleichsofferte, Neubau',
  },
  counselor: {
    title: 'Ratgeber-Artikel | HausHeld',
    description:
      'Hier finden Sie Informationen für Ihre Renovations- und Umbauprojekte, Tipps rund um das Thema Eigenheim, Finanzen, Energetisch Sanieren und mehr.',
    keywords: 'Haus Ratgeber, Umbauprojekt, Haus Blog, Eigenheim Planung',
  },
  getPartner: {
    title: 'Renovationsaufträge von Hauseigentümern erhalten - werden Sie Partner von Hausheld!',
    description:
      'Über das Qualitäts-Renovationsportal Hausheld.ch können Haus- und Stockwerkeigentümer Renovationsprojekte aller Art an Handwerker vergeben. Wir prüfen jede Anfrage telefonisch. Gewinnen Sie schweizweit hochwertige Kundenanfragen.',
    keywords: 'Handwerker aufträge, aufträge erhalten, Schweizer Liegenschaftseigentümer',
  },
  aboutUs: {
    title: 'Über uns: HausHeld.ch - Ganz einfach zu Ihren Handwerker Helden ',
    description:
      'Wir sind ein etabliertes Startup mit Sitz in Herisau. Unseren Kunden vermitteln wir seriöse und passende Handwerker-Helden - kostenlos und unverbindlich',
    keywords: 'HausHeld.ch, Vermando AG, Herisau, HausHelden',
  },
  contactDE: {
    title: 'Kontakt: Einfach zu Ihren Handwerker-Helden auf HausHeld.ch',
    description:
      'Wir sind immer offen für Ihre Anliegen. Stellen Sie Ihre Frage einfach per Formular. Wir werden uns schnellstmöglich bei Ihnen melden.',
    keywords: 'Kontakt HausHeld, Telefonnummer Hausheld',
  },
  contactFR: {
    title: `Prendre contact : Tout simplement vers vos artisans-héros sur ${getSiteName()}`,
    description: `Nous sommes toujours ouverts à vos demandes. Posez simplement votre question via le formulaire. Nous vous répondrons dans les plus brefs délais.`,
    keywords: 'Contact HausHeld, numéro de téléphone Hausheld',
  },
  aufzugoContact: {
    title: 'Kontaktieren Sie uns | aufzugo',
    description: 'Haben Sie eine Frage oder möchten uns etwas mitteilen? Kontaktieren Sie uns.',
    keywords: '',
  },
  airConditioningDE: {
    title: 'Klima | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'HausHeld findet passende Fachbetriebe für den Einbau von Klimaanlagen in Ihrer Region. Kostenlos und schnell',
    keywords:
      'Klimaanlage, klimaanlage für wohnung, fensterabdichtung klimaanlage, klimaanlage installation',
  },
  alarmSystemDE: {
    title: 'Alarmanlagen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Passend zu Ihrer Situation findet HausHeld.ch die richtigen Fachbetriebe für die Installation von Alarmanalgen mit Videoüberwachung',
    keywords: 'Alarmanlagen, Alarmsysteme, Videoüberwachung, Einbruchschutz',
  },
  architectDE: {
    title: 'Architekt & Bauleiter | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Bei HausHeld.ch können Sie Architekten und Bauleiter für Ihr Projekt finden. Wir vermitteln bis zu drei selbstständige Architekten und Bauleiter.',
    keywords:
      'bauleiter, architektur bauleitung, suche bauleiter, selbstständiger bauleiter gesucht',
  },
  assemblingDE: {
    title:
      'Küchenaufbau? Schrankaufbau? Anbringen von Regalen und sontige Monatgearbeiten? Die Montagepartner von HausHeld.ch helfen Ihnen schnell',
    description:
      'Küchenaufbau? Schrankaufbau? Anbringen von Regalen und sontige Monatgearbeiten? Die Montagepartner von HausHeld.ch helfen Ihnen schnell',
    keywords: 'Montage, Montagearbeiten, montagefirma',
  },
  awningsDE: {
    title: 'Markisen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Profibetrieb für die Markise gesucht? Über das Renovationsprotal HausHeld.ch erhalten Sie Angeboten für Ihren Sonnenschutz und Ihr Beschattungssystem',
    keywords: 'markisen, sonnenstoren, sonnenschutz, Markisen Schweiz',
  },
  balconyDE: {
    title: 'Balkon | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Sie suchen Lösungen für einen Balkonanbau oder eine Balkonsanierung? Wir haben passende Fachfirmen in Ihrer Region!',
    keywords: 'Balkonanbau, Balkongeländer, Balkonsanierung',
  },
  bathDE: {
    title: 'Badesanierung | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Planen Sie eine Badsanierung? Mit HausHeld.ch bekommen einfach Sie bis zu drei Offerten von Sanitärbetrieben',
    keywords:
      'badsanierung, badezimmer renovieren, was kostet eine badesanierung, badezimmer sanitär',
  },
  carportDE: {
    title: 'Carport | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'HausHeld.ch vermittelt Ihnen Handwerker die auf Carports und Autounterstände spezialisiert sind, damit Sie den Preis vergleichen können',
    keywords: 'carport, carport holz, carport metall, carport schweiz',
  },
  brokerDE: {
    title: `Wir finden ihren Makler | ${getSiteName()}`,
    description: `So einfach finden Sie Ihren Wunschmakler: 1. Verkaufsprojekt erfassen. 2. Maklervorschlag erhalten. 3. Gratis vor Ort Beratung und Bewertung`,
    keywords: 'Makler, immobilien bewerten, immobilie verkaufen, haus kaufen',
  },
  carpenterDE: {
    title: 'Schreiner | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Brauchen Sie ein Schreiner für Ihr Möbel auf Mass? HausHeld.ch vermittelt Schreinereien die Ihr Projekt verwirklichen können',
    keywords: 'Möbel, schreinerei, holz auf mass bestellen, schreiner möbel',
  },
  chargingColumnDE: {
    title: 'Ladestationen für Elektroautos | Kostenlose Offerten einholen | Hausheld.ch',
    description:
      'e-Mobilität für Ihr Elektroauto einfach gemacht! Finden Sie die besten Anbieter für Elektro-Ladestationen in der Schweiz bei Hausheld.ch',
    keywords:
      'elektro ladestation, ladestation elektroauto schweiz, e-mobilität, elektromobilität schweiz',
  },
  chippieDE: {
    title: 'Zimmermann | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Wollen Sie Ihr Haus renovieren? Mit den Handwerker von HausHeld.ch können Sie Ihre Renovierung beginnen',
    keywords: 'zimmermann, zimmermann entsorgung, holzarbeiten',
  },
  electricianDE: {
    title: 'Elektriker | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Elektroinstallationen sind für Sie kompliziert? Mit HausHeld.ch finden sie bis zu drei Elektriker für Ihre Elektrotechnik',
    keywords: 'Elektriker, elektroinstallateur, elektroinstallation, elektotechnik',
  },
  facadeDE: {
    title: 'Fassaden | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Ihre Fassade muss erneuert werden oder isoliert? HausHeld.ch kann Ihnen bei Ihrem Projekt helfen',
    keywords: 'fassade sanieren, Fassade renovieren, Fassade erneurn, fassade isolieren',
  },
  fencesDE: {
    title: 'Zaun | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Wollen Sie einen Zaun bauen lassen? HausHeld.ch hat die Spezialisten für Ihr Projekt, sei es einen normalen Zaun oder Gartenzaun',
    keywords: 'gartenzaun, zaun zäune, zaun bauen lassen',
  },
  floorerDE: {
    title: 'Bodenleger | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Muss ihr Boden neu verlegt werden? Bei HausHeld.ch vermittelt bis zu drei Bodenleger, die Ihr Projekt angehen möchten',
    keywords: 'bodenleger, boden verlegen, laminat verlegen lassen, bodenlegen',
  },
  garageDE: {
    title: 'Garage | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Elektrisches Garagentor oder doch lieber ein Sektionaltor? HausHeld.ch hat die Handwerker für Ihre Garage',
    keywords: 'garagentor kaufen, elektrisches garagentor, garagen tor, sektionaltore preise',
  },
  gardenerDE: {
    title: 'Gärtner | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Gartenpflege wird einfacher gemacht mit HausHeld.ch. Finden Sie den richtigen Gärtner für Ihren Gartenbau',
    keywords: 'gartenbau, gartenpflege, garten landschaftsbau, gärtner landschaftsbau',
  },
  heatingDE: {
    title: 'Heizungsanlagen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Wärmepumpe? Pelletheizung? Oder Ölheizung? Finden Sie den passenden Handwerker für Ihre Heizungsanlage',
    keywords: 'wärmepumpe preisliste, pelletheizung, heizunsanlagen, wärmepumpen',
  },
  kitchenDE: {
    title: 'Küchen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Neue Küche fällig? HausHeld.ch findet für Sie bis zu drei Küchenbauern in den Schweiz um Ihr Projekt zu verwirklichen',
    keywords: 'küchenbauer schweiz, neue küche, küche schweiz, kosten küche',
  },
  kitchenFR: {
    title: `Cuisines | Demander des devis gratuits | ${getSiteName()}`,
    description: `Vous avez besoin d'une nouvelle cuisine ? HausHeld.ch vous trouve jusqu'à trois cuisinistes en Suisse pour réaliser votre projet.`,
    keywords: `cuisiniste suisse, nouvelle cuisine, cuisine suisse, coût cuisine`,
  },
  metalConstructionDE: {
    title: 'Metallbau | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Sie suchen einen Metallbaubetrieb für Geländer, Treppen, Überdachung, Balkone oder weitere Projekte aus Metall? Bei HausHeld.ch sind sie genau richtig!',
    keywords: 'schweizer metallbau, metallbau, metallbauunternehmen, metallbaufirmen',
  },
  painterDE: {
    title: 'Maler | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Malerarbeiten sind bei Ihnen gefällig? Bei HausHeld.ch finden Sie den passenden Malermeister',
    keywords: 'maler, malermeister, was kostet ein maler, malerarbeiten',
  },
  paverDE: {
    title: 'Plattenverleger | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Platten können einfach verlegt werden mit den Plattenleger vermittelt durch HausHeld.ch',
    keywords: 'platten verlegen, platten legen, plattenleger kosten, plattengesucht',
  },
  pergolaDE: {
    title: 'Überdachung - Metallbau | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Neubau oder Ersatz einer Überdachnung oder Pergola? Lassen Sie sich kostenlose  Offerten von Fachbetrieben aus Ihrer Region vermitteln',
    keywords: 'pergola, pergola metall, pergola terrasse, pergola aluminium',
  },
  railingDE: {
    title: 'Geländer | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Für den Neubau oder Ersatz von Metallgeländern sucht HausHeld.ch die passenden Fachbetriebe in Ihrer Region. Kostenlos bis zu 3 Offerten einholen',
    keywords: 'Balkongeländer metallbau, geländer balkon, geländer terrasse, geländerpfosten',
  },
  rollerBlindsDE: {
    title: 'Storen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Storen erneuern oder reparieren? HausHeld.ch vermittelt bis zu drei Fachbetriebe, die Lamellen-, Rollladen- oder Stoffstoren in Ihrer Region anbieten',
    keywords: 'storen, storen reparieren, Rollladen, fenster rollos, fenster storen',
  },
  rooferDE: {
    title: 'Bedachung | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Braucht Ihr Haus eine Dachsanierung? HausHeld.ch kann Ihnen bei Ihrer Bedachung bis zu drei Dachdecker gratis vermitteln',
    keywords: 'dachdecker, dachsanierung, bedachungen, dach renovieren kosten',
  },
  solarPlantsDE: {
    title: `Solaranlagen | Kostenlose Offerten einholen | ${getSiteName()}`,
    description: `Photovoltaik oder Solarpanel? ${getSiteName()} vermittelt Fachbetriebe, die Ihre Solaranlage zum top Preis installieren können`,
    keywords: 'solaranlagen schweiz, photovoltaik preise, solarpanel, kosten solaranlage schweiz',
  },
  stairDE: {
    title: 'Treppen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Erneuerung oder Neubau einer Treppe aus Metall? Wir finden für Ihr Projekt passende Metallbaubetriebe für gratis Offerten',
    keywords: 'metallbau treppengeländer, Treppengeländer, Treppenbau, treppe haus',
  },
  waterConditioningDE: {
    title: 'Wasseraufbereitung | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Sauberes Trinkwasser mit Wasseraufbereitung zum Schutz Ihrer Wasserleitungen vor Kalk. HausHeld hat die passenden Fachbetriebe',
    keywords: 'Wasseraufbereitung, Kalkschutz, wasseraufbereitungsanlage',
  },
  whirlpoolDE: {
    title: 'Whirlpool | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Von bis zu drei Whirlpool-Anbietern erhalten Sie unverbindliche Offerten und Beratung für Ihr passendes Jacuzzi-Erlebnis',
    keywords: 'Wwhirlpool, whirlpool outdoor, whirlpool schweiz, jacuzzi',
  },
  windowDE: {
    title: 'Fenster & Türen | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Wollen Sie wissen, wieviel Fenster kosten? Über HausHeld.ch erfahren Sie durch passende Offerten den konkreten Preis zu Ihrem Fensterprojekt',
    keywords: 'fenster, kosten fenster, dachdenster preis, was kosten neue fenster',
  },
  wintergardenDE: {
    title: 'Wintergarten  | Kostenlose Offerten einholen | HausHeld.ch',
    description:
      'Wintergarten aus Holz, Metall oder Kunststoff. Holen Sie sich jetzt passende Offerten von lokalen Anbietern aus der Schweiz',
    keywords: 'Wintergarten, Wintergarten Schweiz, Wintergarten auf Balkon',
  },
  thanks: {
    title: 'Vielen Dank | HausHeld.ch',
    description: 'Vielen Dank für Ihre Anfrage bei HausHeld.ch',
    keywords: 'danke, vielen dank, HausHeld.ch.',
  },
  conditions: {
    title: 'HausHeld.ch: Allgemeine Geschäftsbedingungen',
    description: 'Die allgemeinen Geschäftsbedingungen von HausHeld.ch.',
    keywords: 'agb, allgemeine Geschäftsbedingungen',
  },
  aufzugoConditions: {
    title: 'aufzugo AGBs | Unsere allgemeinen Geschäftsbedingungen',
    description: 'Das Stellen einer Anfrage ist für den Interessenten unverbindlich und kostenlos.',
    keywords: 'agb, allgemeine Geschäftsbedingungen',
  },
  impressum: {
    title: 'HausHeld.ch: Impressum',
    description: 'Das Impressum von HausHeld.ch',
    keywords: 'impressum',
  },
  aufzugoImpressum: {
    title: 'aufzugo | Impressum',
    description: 'Das Impressum von Aufzugo.de',
    keywords: 'impressum',
  },
  dataProtection: {
    title: 'HausHeld.ch: Datenschutz',
    description: 'Die Datenschutzerklärung von HausHeld.ch',
    keywords: 'datenschutz, datenschutzerklärung',
  },
  aufzugoDataProtection: {
    title: 'aufzugo | Datenschutzerklärung',
    description:
      'aufzugo bekennt sich zur EU-Datenschutzgrundverordnung (DSGVO) und den Artikel 13 der schweizerischen Bundesverfassung (Datenschutzgesetz, DSG)',
    keywords: 'datenschutz, datenschutzerklärung',
  },
  keyAspects: {
    title: 'Zentrale Aspekte für Haus- und Wohnungseigentümer | HausHeld-Ratgeber',
    description:
      'Die neusten und beliebtesten Blog-Beiträge für Hauseigentümer und Wohnungseigentürmer. Wissenswertes für ein schönes Zuhause',
    keywords: 'Hauseigentümer, Wohnungseigentümer, Hausbau Blog',
  },
  allArticles: {
    title: 'Wissenswertes für Haus- und Wohnungseigentümer | HausHeld-Ratgeber',
    description:
      'Die neusten und beliebtesten Blog-Beiträge für Hauseigentümer und Wohnungseigentürmer. Wissenswertes für ein schönes Zuhause',
    keywords: 'Hauseigentümer, Wohnungseigentümer, Hausbau Blog',
  },
  renovationTips: {
    title: 'Sanierungs- und Renovationstipps | HausHeld-Ratgeber',
    description:
      'Wie schafft man sich das schönste Eigenheim? Alles zu Komfort und Lebensqualität finden Sie hier bei unseren Sanierungstipps',
    keywords: 'Sanierungstipps, Renovationstipps, Eigenheim, Lebensqualität',
  },
  finance: {
    title: 'Finanzen und Steuern für das eigene Zuhause müssen nicht kompliziert sein!',
    description:
      'Viele finanzielle Vorteile und Antworten zu Steuerfragen finden Sie im HausHeld-Ratgeber. Sichern Sie sich nur das Beste für Ihr Zuhause',
    keywords: 'Steuern Haus, finanzen haus, Steuervorteile, Steuerfragen',
  },
  energeticRenovation: {
    title: 'Die neusten Tipps und Tricks zum energetischen Sanieren - Sparen & Gutes tun!',
    description:
      'Energie sparen? Durch energetisches Sanieren sparen sie nicht nur Geld, sondern schonen auch die Umwelt! ',
    keywords: 'Energie sparen, energetisches Sanieren, Geld sparen',
  },
  partnershipProgram: {
    title: 'Profitieren Sie von unserem Partnerprogramm',
    description:
      'Kommen Sie mit wenig Aufwand zu vielen Aufträgen. Wir sind Experten im Bereich Digitalisierung und Performance Marketing. Über das innovative Renovationsportal Hausheld.ch können Haus- und Stockwerkeigentümer Renovationsprojekte aller Art eingeben. Wir prüfen jede Anfrage telefonisch. Unsere Partner erhalten dadurch schweizweit hochwertige Kundenanfragen mit sehr guten Auftragsquoten.',
    keywords: 'Handwerker aufträge, aufträge erhalten, Schweizer Liegenschaftseigentümer',
  },
  others: {
    title: 'Jede menge Wissen rund um das Thema Haus | HausHeld-Ratgeber',
    description:
      'Weitere Informationen rund im das Thema Haus und Wohnung finden Sie in dieser Kategorie. Zusatzwissen garantiert!',
    keywords: 'Haus Blog, Haus Ratgeber, Informationen Sanierung',
  },
  wir: {
    title: 'Sichern Sie neue Handwerker-Aufträge mit WIR | HausHeld',
    description: `1'000 CHF Starterpaket mit 100% WIR bezahlen. Finden Sie einfacher neue Aufträge mit Ihrem persönlichen Kundengenerator von Hausheld.ch!`,
    keywords: '',
  },
  sponsoring: {
    title: 'Ratgeber publizieren | HausHeld',
    description: 'Platzieren Sie Ihr Angebot bei Immobilieneigentümer zum richtigen Zeitpunkt',
    keywords: '',
  },
  error: {
    title: 'HausHeld.ch: Fehler-Seite',
    description: 'Leider haben sie die Voraussetzungen für eine Hypothek nicht erfüllt.',
    keywords: 'hypothek, hausheld.ch.',
  },
  mortgage: {
    title: 'Top Anschlussfinanzierung sichern | HausHeld',
    description:
      'Wir haben mit seriösen Finanzpartnern Vergleichstools entwickelt, mit denen Sie in wenigen Minuten eine Anschlusshypothek beantragen können.',
    keywords: '',
  },
  rejection: {
    title: 'Hypothek | Danke',
    description:
      'Wir haben mit seriösen Finanzpartnern Vergleichstools entwickelt, mit denen Sie in wenigen Minuten eine Anschlusshypothek beantragen können.',
    keywords: '',
  },
  aufzugoLanding: {
    title: 'aufzugo | Optimieren Sie Wartung, Notruf und Modernisierung',
    description:
      'Finden Sie qualifizierte Anbieter für Aufzugswartung, Notruf und Modernisierung. Erhalten Sie Angebote mit fairem Preis-Leistungs-Verhältnis.',
    keywords: '',
  },
  aufzugoMaintenance: {
    title: 'Aufzugswartung | erhalten Sie Serviceleistung zu fairen Preisen',
    description:
      'Durch den aufzugo-Gütekriterienkatalog erhalten Sie hochwertige und vergleichbare Wartungsangebote für ihre Aufzüge.',
    keywords: '',
  },
  aufzugoModernization: {
    title: 'Aufzugs-Modernisierung | Eine Modernisierung kann die Verfügbarkeit erhöhen',
    description: 'Wir vermitteln Ihnen qualiﬁzierte Unternehmen aus dem deutschen Mittelstand.',
    keywords: '',
  },
  aufzugoEmergencyCall: {
    title: 'Aufzugnotruf | Eine notwendige Sicherheitseinrichtung',
    description:
      'Ein Notrufsystem lässt sich in jedem Aufzug nachrüsten und diesen damit auf den aktuellen Stand der Technik bringen.',
    keywords: '',
  },
  aufzugoNewConstruction: {
    title: 'Aufzugs-Neubau | Ein neuer Aufzug so individuell wie Sie',
    description:
      'Unseren qualifizierten Partner beraten Sie, damit Sie künftig einen zuverlässigen und Ihren Bedürfnissen entsprechenden Aufzug Ihr Eigen nennen können.',
    keywords: '',
  },
  aufzugoGetPartner: {
    title: 'Werden Sie jetzt Partner | aufzugo',
    description:
      'Als Partner erhalten Sie hochwertige Kundenanfragen aus Deutschland. Sie sind auf Ihr Profil abgestimmt, telefonisch geprüft und bieten hohe Auftragsquoten.',
    keywords: '',
  },
  aufzugoAboutUs: {
    title: 'Über uns | aufzugo',
    description:
      'Wir sind Experten der Aufzugsbranche. Unser Team hat langjährige Erfahrung in der Aufzugsbranche und Beratung.',
    keywords: '',
  },
  swissLife: {
    title: 'Swiss Life | HausHeld.ch',
    description: 'iFrames von SwissLife',
    keywords: 'Swiss Life, iFrames, ViBe, HypoCheck, Immobilienwertrechner',
  },
  swissLifeVibe: {
    title: 'Swiss Life virtueller Begleiter ViBe | HausHeld',
    description:
      'Mit dem virtuellen Begleiter ViBe jetzt schnell und einfach Hypothekar-Angebote verschiedener Anbieter vergleichen',
    keywords: '',
  },
  swissLifeHypoCheck: {
    title: 'Swiss Life Hypo-Check | HausHeld',
    description:
      'Hypothekar-Angebote von Banken, Versicherungen und Pensionskassen vergleichen und die beste Finanzierung für Ihre Immobilie finden.',
    keywords: '',
  },
  swissLifePropertyValue: {
    title: 'Swiss Life Immobilienwertechner | HausHeld',
    description: 'Den Wert Ihrer Immobilie berechnen lassen und Geld sparen.',
    keywords: '',
  },
}
