
import Vue from 'vue'
import { projectNameMapping } from '~/core/data/projectNameMapping'

export default Vue.extend({
  name: 'MenuListLarge',
  data() {
    return {
      translatedCraftname: projectNameMapping[this.$config.satelliteName],
    }
  },
})
