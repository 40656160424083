import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const analysisTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'analysis',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  realEstates: [],
  userId: undefined,
  selectedRealEstate: 0,
  onboardingViews: [],
})

export const getters = {
  getRealEstateId(state: any) {
    try {
      return state.realEstates[state.selectedRealEstate].body.id
    } catch {
      return undefined
    }
  },
  getCurrentRealEstate(state: any): object {
    try {
      return state.realEstates[state.selectedRealEstate]
    } catch {
      return {}
    }
  },
  getAllRealEstates(state: any): object[] {
    try {
      return state.realEstates
    } catch {
      return []
    }
  },
  getCurrentRealEstateAddress(state: any): string {
    try {
      return state.realEstates[state.selectedRealEstate].body.addresses[0].body.address
    } catch {
      return ''
    }
  },
  getRealEstateAddresses(state: any): string[] {
    try {
      const addresses: string[] = []
      for (const realEstate of state.realEstates) {
        addresses.push(realEstate.body.addresses[0].body.address)
      }
      return addresses
    } catch {
      return []
    }
  },
  getComponents(state: any) {
    try {
      return state.realEstates[state.selectedRealEstate].body.components
    } catch {
      return []
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch {
      return undefined
    }
  },
  getSelectedRealEstate(state: any) {
    try {
      return state.selectedRealEstate
    } catch {
      return 0
    }
  },
  getOnboardingViews(state: any) {
    try {
      return state.onboardingViews
    } catch {
      return []
    }
  },
}

export const mutations = {
  setRealEstates(state: any, realEstates: object[]) {
    state.realEstates = realEstates
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
    state = localState
  },
  setOnboardingViews(state: any, onboardingViews: string[]) {
    state.onboardingViews = onboardingViews
  },
  async setUserLocal(state: any) {
    await analysisTable.setItem('analysis', state)
  },
  setRealEstate(state: any, pos: number) {
    state.selectedRealEstate = pos
  },
  sortComponentByGoodConditionUntil(state: any) {
    try {
      return state.realEstates[state.selectedRealEstate].body.components.sort((a: any, b: any) => {
        return a.body.goodConditionUntil < b.body.goodConditionUntil ? -1 : 1
      })
    } catch (error) {
      return []
    }
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets user from localForage first
    const analysis: any | null = await analysisTable.getItem('analysis')
    if (analysis) {
      context.commit('setState', analysis)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setOnboardingViews', data.user.body.onboardingViews)
      context.commit('setRealEstates', data.user.body.realEstates)
      context.commit('setUserId', data.user.body.id)
      context.commit('setUserLocal')
    })
  },
  /* eslint-disable */
  async postMutation(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
  async getComponentById(context: any, id: number) {
    const components = await context.getters.getComponents
    try {
      for (const component of components) {
        if (component.body.id === id) {
          return component
        }
      }
    } catch (error) {
      return {}
    }
  },
  async getRealEstateById(context: any, id: number): Promise<object> {
    const realEstates = await context.getters.getAllRealEstates
    for (const realEstate of realEstates) {
      if (realEstate.body.id === id) {
        return realEstate
      }
    }
    return {}
  },
}
