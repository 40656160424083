
import Vue from 'vue'
import RealEstateDropdown from '~/components/dropdowns/RealEstateDropdown.vue'
import ErrorToast from '~/components/toasts/ErrorToast.vue'

export default Vue.extend({
  components: { ErrorToast, RealEstateDropdown },
  data() {
    return {
      logoutError: false,
      creationDropdownVisible: false,
    }
  },
  methods: {
    showCreateDropdown() {
      this.$data.creationDropdownVisible = true
    },
    hideCreateDropdown() {
      this.$data.creationDropdownVisible = false
    },
    logout() {
      this.$auth
        .logout()
        .then(() => {
          // Forward to landing page
          location.assign(`/${this.$i18n.locale}/`)
        })
        .catch(() => {
          this.logoutError = true
        })
    },
  },
})
