
import Vue from 'vue'
export default Vue.extend({
  props: {
    error: {
      type: String,
      required: true,
    },
    display: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
