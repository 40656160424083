import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const todosTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'todos',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  todos: [],
  userId: undefined,
})

export const getters = {
  getTodos(state: any) {
    try {
      return state.todos
    } catch {
      return []
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch {
      return undefined
    }
  },
}

export const mutations = {
  setTodos(state: any, todos: object[]) {
    state.todos = todos
  },
  addProjectTagsToTodos(state: any, projects: any[]) {
    for (const todo of state.todos) {
      if (todo.body.ownerType === 'PROJECT') {
        for (const project of projects) {
          if (project.body.id === todo.body.ownerId) {
            todo.body.tag = project.body.request.body.craftType
          }
        }
      }
    }
  },
  addRealEstateTagsToTodos(state: any, realEstates: any[]) {
    for (const todo of state.todos) {
      if (todo.body.ownerType === 'REALESTATE') {
        for (const realEstate of realEstates) {
          if (realEstate.body.id === todo.body.ownerId) {
            todo.body.tag = realEstate.body.addresses[0].body.address
          }
        }
      }
    }
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
        state = localState
  },
  async setTodosLocal(state: any) {
    await todosTable.setItem('todos', state)
  },

  /**
   * Sorts todos by state and then by creation date
   */
  sortToDos(state: any) {
    const sortOrder = ['OPEN', 'DONE']
    const todos = state.todos
    todos.sort(function (a: any, b: any) {
      if (a.body.status === b.body.status) {
        return a.body.creationDate < b.body.creationDate ? 1 : -1
      }
      return sortOrder.indexOf(a.body.status) - sortOrder.indexOf(b.body.status)
    })
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets user from localForage first
    const todos: any | null = await todosTable.getItem('todos')
    if (todos) {
      context.commit('setState', todos)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setTodos', data.user.body.toDos)
      context.commit('addProjectTagsToTodos', data.user.body.projects)
      context.commit('addRealEstateTagsToTodos', data.user.body.realEstates)
      context.commit('setUserId', data.user.body.id)
      context.commit('setTodosLocal')
    })
  },
  /* eslint-disable */
      async postMutation(context: any, data: any) {
        /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
}
