import localForage from 'localforage'
import * as memoryDriver from 'localforage-driver-memory'
import client from '~/graphql/graphqlClient'

localForage.defineDriver(memoryDriver)
const craftsmenTable = localForage.createInstance({
  name: 'myHausHeld',
  storeName: 'craftsmen',
  driver: [
    localForage.INDEXEDDB,
    localForage.WEBSQL,
    localForage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
})

const cleanToken = (token: string) => {
  return token.replace('Bearer ', '')
}

export const state = () => ({
  craftsmen: [],
  userId: undefined,
})

export const getters = {
  getCraftsmen(state: any) {
    try {
      return state.craftsmen
    } catch {
      return []
    }
  },
  getUserId(state: any) {
    try {
      return state.userId
    } catch (error) {
      return undefined
    }
  },
}

export const mutations = {
  setCraftsmen(state: any, craftsmen: object) {
    state.craftsmen = craftsmen
  },
  setUserId(state: any, userId: number) {
    state.userId = userId
  },
  setState(state: any, localState: any) {
    // eslint-disable-next-line
    state = localState
  },
  async setCraftsmenLocal(state: any) {
    await craftsmenTable.setItem('craftsmen', state)
  },
}

export const actions = {
  async getDataOffline(context: any) {
    // Gets projects from localForage first
    const craftsmen: any | null = await craftsmenTable.getItem('craftsmen')
    if (craftsmen) {
      context.commit('setState', craftsmen)
    }
  },
  async getDataOnline(context: any, data: any) {
    const c = client(cleanToken(data.token))
    await c.request(data.query, data.variables).then((data: any) => {
      context.commit('setCraftsmen', data.user.body.craftsmen)
      context.commit('setUserId', data.user.body.id)
      context.commit('setCraftsmenLocal')
    })
  },
  /* eslint-disable */
  async postMutation(context: any, data: any) {
    /* eslint-enable */
    const c = client(cleanToken(data.token))
    return await c.request(data.mutation, data.variables)
  },
}
