
import Vue from 'vue'
import { capitalizeFirstLetterOnly } from '~/core/functions/capitalizeFirstLetterOnly'

export default Vue.extend({
  name: 'SatelliteLogo',
  methods: {
    capitalizeFirstLetterOnly,
  },
})
