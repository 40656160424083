
import Vue from 'vue'
import CookieConsent from '~/components/cookieConsent/CookieConsent.vue'
import Loader from '~/components/loader/Loader.vue'
import TopNavigation from '~/components/navigations/myHausHeld/desktop/TopNavigation.vue'
import BottomNavigation from '~/components/navigations/myHausHeld/mobile/BottomNavigation.vue'
import MobileHeader from '~/components/navigations/myHausHeld/mobile/MobileHeader.vue'
import { head } from '~/core/config/head.config'
import { handleCookieConsent, setReferrerCookie } from '~/core/functions/cookie'
import { getUserMinData } from '~/graphql/queries/userMinData'

export default Vue.extend({
  components: { TopNavigation, MobileHeader, BottomNavigation, CookieConsent, Loader },
  data() {
    return {
      showCookieConsent: false,
      loadingMinData: true,
    }
  },
  // Fetch required to display real estate addresses in top navigation
  async fetch() {
    const data = {
      // @ts-ignore
      token: this.$auth.strategy.token.get(),
      variables: {
        // @ts-ignore
        uuid: this.$auth.user.id,
      },
      query: getUserMinData,
    }
    await this.$store.dispatch({ ...data, type: 'analysis/getDataOffline' })
    if (this.$nuxt.isOnline) {
      await this.$store.dispatch({ ...data, type: 'analysis/getDataOnline' })
    }
    this.$data.loadingMinData = false
  },
  head() {
    head.bodyAttrs.class = 'user-area-theme mh'
    return head
  },
  created() {
    setReferrerCookie(this)
    handleCookieConsent(this)
  },
})
