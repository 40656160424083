
import Vue from 'vue'
export default Vue.extend({
  name: 'ToggleSwitch',
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    defaultValue: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      checked: this.$props.defaultValue,
    }
  },
  methods: {
    toggleSwitch() {
      if (!this.$props.disabled) {
        this.checked = !this.checked
      }
    },
  },
})
