import { meta } from './data/meta.data'

export const head = {
  title: 'Kostenlose Handwerker-Offerten für Ihre Renovation| HausHeld.ch',
  bodyAttrs: {
    class: 'public-area-theme',
  },
  htmlAttrs: {
    dir: 'ltr',
    lang: 'de',
  },
  link: [],
  meta: [],
}

/**
 * Generates the head of a specific page
 * @param page {string} - Name of the page
 * @returns The head of the request page
 */
export const generateHead = (page: string) => {
  const generatedHead = JSON.parse(JSON.stringify(head))
  generatedHead.title = meta[page].title
  generatedHead.meta.push({
    hid: 'og:title',
    name: 'og:title',
    property: 'og:title',
    content: meta[page].title,
  })
  generatedHead.meta.push({
    hid: 'description',
    name: 'description',
    property: 'description',
    content: meta[page].description,
  })
  generatedHead.meta.push({
    hid: 'og:description',
    name: 'og:description',
    property: 'og:description',
    content: meta[page].description,
  })
  if (meta[page].keywords !== '') {
    generatedHead.meta.push({
      hid: 'keywords',
      name: 'keywords',
      property: 'keywords',
      content: meta[page].keywords,
    })
  }
  return generatedHead
}

/**
 * Generates the head of a specific page without adding title, keywords and description
 * @returns The head of the request page
 */
export const generateHeadWithoutMetaData = () => {
  const generatedHead = JSON.parse(JSON.stringify(head))
  generatedHead.title = ''
  return generatedHead
}
