
import Vue from 'vue'
import Logo from '~/components/images/MyHausHeldLogo.vue'
import UserAreaNavigation from '~/components/navigations/myHausHeld/desktop/UserAreaNavigation.vue'
import LandingNavigation from '~/components/navigations/myHausHeld/desktop/LandingNavigation.vue'

export default Vue.extend({
  components: { Logo, UserAreaNavigation, LandingNavigation },
  computed: {
    navigation(): string {
      return this.$nuxt.$data.layoutName === 'userArea' ? 'container-my-hausheld ' : 'container'
    },
  },
})
