import { gql } from 'graphql-request'

/* eslint-disable */
export const getUserMinData = gql`
query getRealEstateBaseData($uuid: String!) {
    user(uuid: $uuid) {
      code
      success
      body {
        ... on User {
          id
          onboardingViews
          realEstates {
            code
            success
            body {
              ... on RealEstate {
                id
                addresses {
                  code
                  success
                  body {
                    ... on Address {
                      id 
                      address
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
/* eslint-enable */
