
import Vue from 'vue'
import CookieConsent from '~/components/cookieConsent/CookieConsent.vue'
import FooterComponent from '~/components/navigations/aufzugo/Footer.vue'
import TopNavigation from '~/components/navigations/aufzugo/TopNavigation.vue'
import { head } from '~/core/config/head.config'
import { handleCookieConsent } from '~/core/functions/cookie'

export default Vue.extend({
  name: 'AufzugoLayout',
  components: { TopNavigation, FooterComponent, CookieConsent },
  data() {
    return {
      showCookieConsent: false,
    }
  },
  head() {
    head.bodyAttrs.class = 'public-area-theme aufzugo'
    return head
  },
  created() {
    handleCookieConsent(this)
  },
})
