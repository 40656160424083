
import Vue from 'vue'
import TopNavigation from '~/components/navigations/aufzugo/TopNavigation.vue'
import { generateHead } from '~/core/config/head.config'
import FooterComponent from '~/components/navigations/aufzugo/Footer.vue'
import { gatherBrowserInformation } from '~/core/functions/gatherBrowserInformation'

export default Vue.extend({
  name: 'ErrorLayout4',
  components: { TopNavigation, FooterComponent },
  /* istanbul ignore next */
  head() {
    return generateHead('landingDE')
  },
  async mounted() {
    await this.$axios.post(`${this.$config.vermandoNotification}/send-mail/`, {
      input: {
        from: 'kontakt@hausheld.ch',
        to: 'daniel.hefti@vermando.com',
        subject: `Fehlermeldung auf Seite ${this.$config.baseUrl}`,
        variables: {
          content: JSON.stringify(gatherBrowserInformation()),
        },
        template: 'default',
      },
    })
  },
})
